<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <!-- 사업장 -->
            <c-label-text title="LBLPLANT" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비유형 -->
            <c-label-text title="LBL0001720" :value="param.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비관리번호 -->
            <c-label-text title="LBL0001727" :value="param.equipmentNo"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비명 -->
            <c-label-text title="LBL0001725" :value="param.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabheight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :param="param"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
1
<script>
export default {
  name: 'equipment-etc',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      tab: 'equipmentGrade',
      tabItems: [
        // 관련 도면
        { name: 'equipmentGrade', icon: 'filter', label: 'LBL0003325', component: () => import(`${'./equipmentDrawing.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 200);
    },
  },
  watch: {
  },
  methods: {
    init() {
    },
  }
};
</script>